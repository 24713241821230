<template>
  <div class="fixed-top">
    <nav
      class="navbar py-0"
      :class="[$route.name === 'Home' ? '__moderate' : '_bg-blur _bg-body-a85 border-bottom',]"
    >
      <div class="container-fluid">
        <div>
          <router-link
            :to="{name: 'Home'}"
            class="navbar-brand fw-lighter text-reset me-0 py-2 pe-2 d-inline-block"
            :class="[
              $route.name !== 'Home' ? '_slidefadein' : 'd-none',
            ]"
          >
            <Favicon
              :width="24"
              :height="24"
              class="d-none"
            />
            <span class="ms-0">dials.style</span>
          </router-link>
          <span
            role="separator"
            class="text-muted d-inline-block _slidefadein"
            style="animation-delay: 0.03s;"
            v-if="$route.name !== 'Home'"
          >
            >
          </span>
          <h1
            class="ms-2 d-inline-block navbar-brand
              mb-0 text-lowercase text-muted _slidefadein"
            v-if="$route.name !== 'Home'"
            style="animation-delay: 0.06s;"
          >
            {{ $route.name }}
          </h1>
        </div>

        <ul class="d-flex justify-content-end list-unstyled my-1">
          <transition name="fade" class="d-none">
            <li
              class="align-self-center"
              style="transition-delay: 0.025s;"
              v-show="$route.name !== 'Home'"
            >
              <router-link
                :to="{name: 'About'}"
                class="text-reset text-decoration-none p-2"
              >
                about
              </router-link>
            </li>
          </transition>

          <transition name="fade" class="d-none">
            <li
              class="align-self-center"
              v-show="$route.name !== 'Home'"
            >
              <a
                href="https://marron-web.site"
                target="_blank" ref="noopener"
                class="text-reset text-decoration-none p-2"
              >
                made by
                <ExternalIcon/>
              </a>
            </li>
          </transition>

          <li>
            <button
              type="button"
              ref="toggleButton"
              @click="toggle"
              title="select dial"
              class="btn btn-link nav-link text-reset rounded"
              style="margin-right: -16px;"
            >
              <svg
                style="width:24px;height:24px"
                viewBox="0 0 24 24"
                role="img"
              >
                <path
                  fill="currentColor"
                  v-show="$route.name !== 'Select'"
                  d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3"
                ></path>
                <g
                  stroke="currentColor"
                  stroke-width="2"
                  v-show="$route.name === 'Select'"
                >
                  <line x1="2" x2="22" y1="2" y2="22"></line>
                  <line x1="2" x2="22" y1="22" y2="2"></line>
                </g>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import Favicon from '@/components/Icons/Favicon.vue';
import ExternalIcon from '@/components/Icons/External.vue';

export default {
  components: {
    Favicon,
    ExternalIcon,
  },
  methods: {
    toggle() {
      if (this.$route.name !== 'Select') {
        this.$router.push({ name: 'Select' });
      } else {
        this.$router.go(-1);
        this.$refs.toggleButton.blur();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.__moderate {
  opacity: 0.15;
  transition: opacity .25s;

  &:hover,
  &:focus-within {
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .25s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

._slidefadein {
  opacity: 0;
  transform-origin: 50%;
  transform: translateX(-10px);

  animation-name: fadein;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}
@keyframes fadein {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
