import { createRouter, createWebHistory } from 'vue-router';
import $store from '../store/index';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'dials.style',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'about: dials.style',
    },
  },
  {
    path: '/select',
    name: 'Select',
    component: () => import('../views/Select.vue'),
    meta: {
      title: 'select: dials.style',
    },
  },
  {
    path: '/dialset/:name',
    name: 'Dialset',
    beforeEnter: (to, from, next) => {
      $store.commit('changeDialStyle', to.params.name);
      next({ name: 'Home' });
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'Notfound',
    component: () => import('../views/Notfound.vue'),
    meta: {
      title: 'notfound: dials.style',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
  next();
});

export default router;
