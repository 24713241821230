<template>
  <div class="d-inline">
    <svg role="img" stroke-linecap="round"
      :width="width" :height="height" viewBox="0 0 24 24"
      stroke-width="0.25" stroke="currentColor" fill="none"
    >
      <g class="">
        <clipPath id="cut-off-top">
          <rect x="0" y="0" width="48" height="12"
            transform="rotate(-30) translate(-8, 4)"
          ></rect>
        </clipPath>
        <clipPath id="cut-off-bottom">
          <rect x="0" y="12" width="48" height="24"
            transform="rotate(-30) translate(-8, 4)"
          ></rect>
        </clipPath>
        <g fill="currentColor" stroke="none">
          <circle cx="12" cy="12" r="12" class="_text-inverse"
            fill="currentColor" clip-path="url(#cut-off-top)"
          ></circle>
          <circle cx="12" cy="12" r="12"
            fill="currentColor" clip-path="url(#cut-off-bottom)"
          ></circle>
        </g>

        <g stroke-width="0.5">
          <circle cx="12" cy="12" r="9" fill="none"
            stroke="currentColor" clip-path="url(#cut-off-top)"
          ></circle>
          <circle cx="12" cy="12" r="9" fill="none" class="_text-inverse"
            stroke="currentColor" clip-path="url(#cut-off-bottom)"
          ></circle>
        </g>

        <g>
          <line stroke-width="1.5" x1="12" y1="12" x2="12" y2="2"
            stroke-linecap="square"
          ></line>
          <line stroke-width="1.8" stroke="currentColor"
            x1="12" y1="12" x2="12" y2="2.8"
            transform="rotate(150) translate(-16.6, -28)"
            class="_text-inverse" stroke-linecap="butt"
          ></line>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>
