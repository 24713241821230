<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';
import P5 from 'p5';

export default {
  name: 'DialTimeRing',
  setup() {
    return {
      cs: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_eLVJEz',
      p5: null,
      padding: 25,

      colors: {
        light: {
          bg: 'white',
          base: '#6e4a55',
        },
        dark: {
          bg: 'rgb(22, 24, 33)',
          base: '#e4d2d8',
        },
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);

      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);
      };

      this.p5.draw = () => {
        this.p5.clear();

        const cx = this.p5.windowWidth / 2;
        const cy = this.p5.windowHeight / 2;
        const diameter = Math.min(this.p5.windowWidth, this.p5.windowHeight) - this.padding * 2;

        const hr = (diameter * 2.5) / 5;
        const hAngle = this.p5.map(
          this.p5.hour() + this.p5.norm(this.p5.minute(), 0, 60),
          0, 24, 0, this.p5.TWO_PI * 2,
        ) - this.p5.HALF_PI;
        const mr = (diameter * 3.5) / 5;
        const mAngle = this.p5.map(
          this.p5.minute() + this.p5.norm(
            this.p5.second() + new Date().getMilliseconds() / 1000,
            0, 60,
          ),
          0, 60, 0, this.p5.TWO_PI,
        ) - this.p5.HALF_PI;
        const sr = (diameter * 4.5) / 5;
        const sAngle = this.p5.map(
          this.p5.second() + new Date().getMilliseconds() / 1000,
          0, 60,
          0, this.p5.TWO_PI,
        ) - this.p5.HALF_PI;

        this.p5.strokeWeight(1)
          .stroke(this.colors[this.cs].base)
          .fill(this.colors[this.cs].bg)
          .circle(cx, cy, diameter)
          .circle(
            cx, cy,
            (diameter * 4) / 5,
          )
          .circle(
            cx, cy,
            (diameter * 3) / 5,
          );
        this.p5.strokeWeight(1)
          .stroke(this.colors[this.cs].base)
          .fill(this.colors[this.cs].bg)
          .circle(
            cx, cy,
            (diameter * 2) / 5,
          );

        // 秒・分・時が1周したときのトランジション処理
        if (this.p5.second() >= 0 && this.p5.second() <= 1) {
          const s = this.p5.second() + new Date().getMilliseconds() / 1000;
          const alphacolor = this.p5.color(this.colors[this.cs].base);
          alphacolor.setAlpha(255 - 255 * s);

          this.p5.strokeWeight(diameter * 0.09)
            .stroke(alphacolor)
            .noFill()
            .circle(cx, cy, sr);

          if (this.p5.minute() === 0) {
            this.p5.strokeWeight(diameter * 0.09)
              .stroke(alphacolor)
              .noFill()
              .circle(cx, cy, mr);

            if (this.p5.hour() === 0 || this.p5.hour() === 12) {
              this.p5.strokeWeight(diameter * 0.09)
                .stroke(alphacolor)
                .noFill()
                .circle(cx, cy, hr);
            }
          }
        }

        // 時針の処理
        this.p5.strokeWeight(diameter * 0.09)
          .stroke(this.colors[this.cs].base)
          .noFill()
          .arc(
            cx, cy,
            hr, hr,
            this.p5.HALF_PI * -1, hAngle,
            this.p5.OPEN,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            'h',
            cx, cy - hr / 2,
          );
        this.p5
          .noStroke()
          .fill(this.colors[this.cs].base)
          .circle(
            cx + (hr * this.p5.cos(hAngle)) / 2,
            cy + (hr * this.p5.sin(hAngle)) / 2,
            diameter * 0.09,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            `0${this.p5.hour()}`.slice(-2),
            cx + (hr * this.p5.cos(hAngle)) / 2,
            cy + (hr * this.p5.sin(hAngle)) / 2,
          );

        // 分針の処理
        this.p5.strokeWeight(diameter * 0.09)
          .stroke(this.colors[this.cs].base)
          .strokeCap(this.p5.ROUND)
          .noFill()
          .arc(
            cx, cy,
            mr, mr,
            this.p5.HALF_PI * -1, mAngle,
            this.p5.OPEN,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            'm',
            cx, cy - mr / 2,
          );
        this.p5
          .noStroke()
          .fill(this.colors[this.cs].base)
          .circle(
            cx + (mr * this.p5.cos(mAngle)) / 2,
            cy + (mr * this.p5.sin(mAngle)) / 2,
            diameter * 0.09,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            `0${this.p5.minute()}`.slice(-2),
            cx + (mr * this.p5.cos(mAngle)) / 2,
            cy + (mr * this.p5.sin(mAngle)) / 2,
          );

        // 秒針の処理
        this.p5.strokeWeight(diameter * 0.09)
          .stroke(this.colors[this.cs].base)
          .noFill()
          .arc(
            cx, cy,
            sr, sr,
            this.p5.HALF_PI * -1, sAngle,
            this.p5.OPEN,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            's',
            cx, cy - sr / 2,
          );
        this.p5
          .noStroke()
          .fill(this.colors[this.cs].base)
          .circle(
            cx + (sr * this.p5.cos(sAngle)) / 2,
            cy + (sr * this.p5.sin(sAngle)) / 2,
            diameter * 0.09,
          );
        this.p5.noStroke()
          .fill(this.colors[this.cs].bg)
          .textSize(diameter / 20)
          .textAlign(this.p5.CENTER, this.p5.CENTER)
          .text(
            `0${this.p5.second()}`.slice(-2),
            cx + (sr * this.p5.cos(sAngle)) / 2,
            cy + (sr * this.p5.sin(sAngle)) / 2,
          );
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
