<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';
import P5 from 'p5';

export default {
  name: 'DialGreece',
  setup() {
    return {
      cs: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_9k7E2H',
      p5: null,
      greeces: [
        'I', 'II', 'III', 'IV', 'V', 'VI',
        'VII', 'VIII', 'IX', 'X', 'XI', 'XII',
      ],
      padding: 25, // => padding 25px
      colors: {
        light: {
          bg: '#ffffff',
          dial: '#fff9f5',
          base: '#8d6449',
        },
        dark: {
          bg: '#161821',
          dial: '#2e2930',
          base: '#ede4e1',
        },
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);
      this.p5.setup = () => {
        // console.log('Greece setup');
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);

        this.p5.textFont('Georgia');
      };

      this.p5.draw = () => {
        // console.log('Greece draw');
        this.p5.clear();

        const cx = this.p5.windowWidth / 2;
        const cy = this.p5.windowHeight / 2;

        this.p5.noStroke();
        this.p5.fill(this.colors[this.cs].dial);
        const diameter = Math.min(this.p5.windowWidth, this.p5.windowHeight) - this.padding * 2;
        this.p5.circle(cx, cy, diameter);

        const s = this.p5.map(this.p5.second(), 0, 60, 0, this.p5.TWO_PI) - this.p5.HALF_PI;
        const m = this.p5.map(this.p5.minute(), 0, 60, 0, this.p5.TWO_PI) - this.p5.HALF_PI;
        const h = this.p5.map(
          this.p5.hour() + this.p5.norm(this.p5.minute(), 0, 60),
          0, 24, 0, this.p5.TWO_PI * 2,
        ) - this.p5.HALF_PI;

        this.p5.strokeCap(this.p5.PROJECT);
        this.p5.stroke(this.colors[this.cs].base);
        this.p5.strokeWeight(1);
        const sr = (diameter / 2) * 0.71;
        this.p5.line(
          cx, cy,
          cx + this.p5.cos(s) * sr,
          cy + this.p5.sin(s) * sr,
        );
        this.p5.line(
          cx, cy,
          cx + this.p5.cos(s + this.p5.PI) * sr * 0.1,
          cy + this.p5.sin(s + this.p5.PI) * sr * 0.1,
        );

        this.p5.strokeWeight(2);
        const mr = (diameter / 2) * 0.6;
        this.p5.line(
          cx, cy,
          cx + this.p5.cos(m) * mr,
          cy + this.p5.sin(m) * mr,
        );
        this.p5.line(
          cx, cy,
          cx + this.p5.cos(m + this.p5.PI) * mr * 0.1,
          cy + this.p5.sin(m + this.p5.PI) * mr * 0.1,
        );

        this.p5.strokeWeight(4);
        const hr = (diameter / 2) * 0.5;
        this.p5.line(cx, cy, cx + this.p5.cos(h) * hr, cy + this.p5.sin(h) * hr);
        this.p5.line(
          cx, cy,
          cx + this.p5.cos(h + this.p5.PI) * hr * 0.1,
          cy + this.p5.sin(h + this.p5.PI) * hr * 0.1,
        );

        for (let a = 0; a < 360; a += 6) {
          const angle = this.p5.radians(a);
          let sx;
          let sy;
          if (a % 30 === 0) {
            this.p5.strokeWeight(2);
            sx = cx + this.p5.cos(angle) * sr * 1.1;
            sy = cy + this.p5.sin(angle) * sr * 1.1;
          } else {
            this.p5.strokeWeight(1);
            sx = cx + this.p5.cos(angle) * sr * 1.15;
            sy = cy + this.p5.sin(angle) * sr * 1.15;
          }

          const x = cx + this.p5.cos(angle) * sr * 1.2;
          const y = cy + this.p5.sin(angle) * sr * 1.2;

          this.p5.line(sx, sy, x, y);
        }

        this.p5.fill(this.colors[this.cs].base);
        this.p5.circle(cx, cy, 8);

        this.p5.textSize(diameter / 25);
        this.p5.noStroke();
        this.p5.fill(this.colors[this.cs].base);
        this.p5.textAlign(this.p5.CENTER, this.p5.CENTER);
        for (let a = 0; a < 360; a += 6) {
          if (a % 30 === 0) {
            const angle = this.p5.radians(a - 60);
            const x = cx + this.p5.cos(angle) * sr;
            const y = cy + this.p5.sin(angle) * sr;
            this.p5.text(this.greeces[a / 30], x, y);
          }
        }

        this.p5.fill(this.colors[this.cs].bg);
        this.p5.circle(cx, cy, 4);
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    const p5canvas = new P5(this.p5script, this.id);
  },
  beforeUnmount() {
    this.p5.remove();
    // console.log('Greece deleted');
  },
};
</script>
