<template>
  <div></div>
</template>

<script>
import { inject } from 'vue';
import P5 from 'p5';

export default {
  name: 'DialDigital',
  setup() {
    return {
      cs: inject('colorScheme'),
    };
  },
  data() {
    return {
      p5: null,
      isFilled: false,
      colors: {
        light: {
          base: '#6e4a55',
        },
        dark: {
          base: '#e4d2d8',
        },
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);
      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        this.p5.createCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };

      this.p5.draw = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
        this.p5.clear();

        const cx = this.p5.windowWidth / 2;
        const cy = this.p5.windowHeight / 2;

        const s = (`0${this.p5.second()}`).slice(-2);
        const m = (`0${this.p5.minute()}`).slice(-2);
        const h = (`0${this.p5.hour()}`).slice(-2);

        if (this.isFilled) {
          this.p5.noStroke();
          this.p5.fill(this.colors[this.cs].base);
        } else {
          this.p5.stroke(this.colors[this.cs].base);
          this.p5.strokeWeight(1);
          this.p5.noFill();
        }
        this.p5.textSize(this.p5.windowWidth / 5);
        this.p5.textAlign(this.p5.CENTER, this.p5.CENTER);
        this.p5.text(`${h}:${m}:${s}`, cx, cy);
      };

      this.p5.mouseClicked = () => {
        this.isFilled = !this.isFilled;
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    const p5canvas = new P5(this.p5script, 'dialcanvas');
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
