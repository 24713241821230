<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';
import moment from 'moment';

const P5 = require('p5');

export default {
  name: 'DialRainy',
  setup() {
    return {
      colorScheme: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_9FQzk9',
      p5: null,

      colors: {
        light: {
          stroke: '#6e4a55',
          bg: 'white',
        },
        dark: {
          stroke: '#e4d2d8',
          bg: 'rgb(22, 24, 33)',
        },
      },

      padding: 20,
      starttime: null,
      raindrops: [],
    };
  },
  methods: {
    addRaindrop(
      _cx = this.p5.windowWidth * Math.random(),
      _cy = this.p5.windowHeight * Math.random(),
      _isFilled = false,
    ) {
      this.raindrops.push({
        cx: _cx,
        cy: _cy,
        createdAt: moment(),
        disappearedAt: moment().add(this.p5.random(3, 4), 'second'),
        isFilled: _isFilled,
        maxR: this.p5.random(
          Math.min(
            this.p5.windowWidth / 3,
            this.p5.windowHeight / 3,
          ),
          Math.max(
            this.p5.windowWidth,
            this.p5.windowHeight,
          ),
        ),
      });
    },
    p5script(_p5) {
      this.p5 = Object.assign(_p5);

      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(
          window.innerWidth,
          window.innerHeight,
        );
        canvas.id(`${this.id}-canvas`);

        this.addRaindrop();

        this.starttime = this.p5.millis();
      };

      this.p5.draw = () => {
        this.p5.clear();

        // 15フレームごとに雨粒を追加
        if (this.p5.frameCount % 15 === 0) {
          this.addRaindrop();
        }

        // disappearedAtを超えた要素を削除
        this.raindrops = this.raindrops.filter(
          (raindrop) => moment().isBefore(raindrop.disappearedAt),
        );

        for (let i = 0; i < this.raindrops.length; i += 1) {
          const start = this.raindrops[i].createdAt;
          const end = this.raindrops[i].disappearedAt;
          const current = moment();

          const percent = moment(start).diff(current, 'millis') / moment(start).diff(end, 'millis');

          const color = this.p5.color(this.colors[this.colorScheme].stroke);
          color.setAlpha(255 - 255 * percent);

          if (this.raindrops[i].isFilled) {
            this.p5.fill(color)
              .noStroke();
          } else {
            this.p5.strokeWeight(1)
              .stroke(color)
              .noFill();
          }
          this.p5.circle(
            this.raindrops[i].cx,
            this.raindrops[i].cy,
            this.raindrops[i].maxR * percent,
          );
        }

        const m = (`0${this.p5.minute()}`).slice(-2);
        const h = (`0${this.p5.hour()}`).slice(-2);

        this.p5
          // .stroke(this.colors[this.colorScheme].stroke)
          // .strokeWeight(2)
          .noStroke()
          // .fill(this.colors[this.colorScheme].bg)
          .fill(this.colors[this.colorScheme].stroke)
          .textSize(this.p5.windowWidth / 10)
          .textAlign(
            this.p5.RIGHT,
            this.p5.BUTTOM,
          )
          .textFont('Helvetica')
          .text(
            `${h}:${m}`,
            this.p5.windowWidth - this.padding,
            this.p5.windowHeight - this.padding,
          );
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };

      this.p5.mousePressed = () => {
        this.addRaindrop(this.p5.mouseX, this.p5.mouseY, true);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
