<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';
import P5 from 'p5';

export default {
  name: 'DialConcentration',
  setup() {
    return {
      cs: inject('colorScheme'),
      ics: inject('inversedColorScheme'),
    };
  },
  data() {
    return {
      id: '_yntcft',
      p5: null,
      padding: 15,
      hasText: false,
      colors: {
        light: {
          base: '#6c4954',
          bg: '#ffffff',
        },
        dark: {
          base: '#e2d0d5',
          bg: '#161821',
        },
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);

      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);
      };

      this.p5.draw = () => {
        this.p5.clear();

        const cx = this.p5.windowWidth / 2;
        const cy = this.p5.windowHeight / 2;
        const diameter = Math.min(this.p5.windowWidth, this.p5.windowHeight) / 2 - this.padding * 2;

        const millisecond = new Date().getMilliseconds();
        const second = this.p5.second() + millisecond / 1000;
        const minute = this.p5.minute() + second / 60;
        const hour = this.p5.hour() + minute / 60;
        // const millisecond = 0;
        // const second = 30;
        // const minute = 9 + 30 / 60;
        // const hour = 10 + (9 + 30 / 60) / 60;

        const color = this.p5.color(this.colors[this.cs].base);
        this.p5.strokeWeight(diameter / 500).stroke(color);

        // const animationDuration = 750; // millisecond
        // if (millisecond >= 0 && millisecond < animationDuration) {
        //   const strokeColor = this.p5.color(this.colors[this.cs].base);
        //   // サインカーブを描いて透明度を下げる
        //   strokeColor.setAlpha(255 - 255 * Math.sin(this.p5.map(
        //     millisecond, 0, animationDuration, 0, 90,
        //   ) * (Math.PI / 180)));
        //   this.p5.stroke(strokeColor).strokeWeight(1).noFill();
        //   this.p5.rect(
        //     (cx + this.padding / 2) - millisecond / 2,
        //     (cy + this.padding / 2) - millisecond / 2,
        //     diameter + millisecond,
        //     diameter + millisecond,
        //   );

        //   this.p5.noStroke().strokeWeight(1);
        // }

        color.setAlpha(255 * (hour / 24));
        // console.log(`h: ${255 * (hour / 24)}`);
        this.p5.fill(color);
        this.p5.rect(
          cx - this.padding / 2,
          cy - this.padding / 2,
          -diameter - this.padding / 2,
          -diameter - this.padding / 2,
        );

        color.setAlpha(255 * (minute / 60));
        // console.log(`m: ${255 * (minute / 60)}`);
        this.p5.fill(color);
        this.p5.rect(
          cx + this.padding / 2,
          cy - this.padding / 2,
          diameter + this.padding / 2,
          -diameter - this.padding / 2,
        );

        color.setAlpha(255 * (millisecond / 999));
        // console.log(`ms: ${255 * (millisecond / 999)}`);
        this.p5.fill(color);
        this.p5.rect(
          cx + this.padding / 2,
          cy + this.padding / 2,
          diameter + this.padding / 2,
          diameter + this.padding / 2,
        );

        color.setAlpha(255 * (second / 60));
        // console.log(`s: ${255 * (second / 60)}`);
        this.p5.fill(color);
        this.p5.rect(
          cx - this.padding / 2,
          cy + this.padding / 2,
          -diameter - this.padding / 2,
          diameter + this.padding / 2,
        );

        if (this.hasText) {
          this.p5.textSize(diameter / 25);
          this.p5.textAlign(this.p5.RIGHT, this.p5.BOTTOM);

          this.p5.fill(Math.round(255 * (hour / 24)));
          this.p5.text('h', cx - this.padding, cy - this.padding);

          this.p5.fill(Math.round(255 * (minute / 60)));
          this.p5.text('m', cx + diameter + this.padding / 2, cy - this.padding);

          this.p5.fill(Math.round(255 * (second / 60)));
          this.p5.text('s', cx - this.padding, cy + diameter + this.padding / 2);

          this.p5.fill(Math.round(255 * (millisecond / 1000)));
          this.p5.text('ms', cx + diameter + this.padding / 2, cy + diameter + this.padding / 2);
        }
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
