<template>
  <svg
    viewBox="0 0 24 24"
    :style="`width:${width}px;height:${height}px;`"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    fill="none"
    role="img"
  >
    <path d="M11,2 2,2 2,22 22,22 22,13"></path>
    <path d="M14,2 22,2 22,10"></path>
    <path d="M22,2 12,12"></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 15,
    },
    height: {
      type: Number,
      default: 15,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
  },
};
</script>
