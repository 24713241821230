import { createStore } from 'vuex';
import Dials from '@/assets/dials.json';
import Colorpalettes from '@/assets/colorpalettes.json';

export default createStore({
  state: {
    isAvailableLocalStorage: false,
    isAvailableShareAPI: false,
    activeSelectTarget: 'dial.style',
    activeDialName: Dials[0].name,
    activeColorPalette: Colorpalettes[0],
  },
  getters: {
    activeSelectTarget: (state) => state.activeSelectTarget,
    activeDialName: (state) => state.activeDialName,
    activeColorPalette: (state) => state.activeColorPalette,
    isAvailableShareAPI: (state) => state.isAvailableShareAPI,
  },
  mutations: {
    save: (state) => {
      localStorage.setItem('state', JSON.stringify(state));
    },
    changeSelectTarget: (state, target) => {
      state.activeSelectTarget = target;
    },
    changeDialStyle: (state, styleName) => {
      state.activeDialName = styleName;
    },
    changeColorPalette: (state, colorPallete) => {
      state.activeColorPalette = colorPallete;
    },
    activateShareAPI: (state) => {
      state.isAvailableShareAPI = true;
    },
  },
  actions: {
  },
  modules: {
  },
});
