<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';

const P5 = require('p5');

export default {
  name: 'DialRainy',
  setup() {
    return {
      colorScheme: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_a55cTp',
      p5: null,
      colors: {
        light: '#6F4B56',
        dark: '#E3D1D7',
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);
      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);
      };

      this.p5.draw = () => {
        this.p5.clear();

        const ww = this.p5.windowWidth;
        const wh = this.p5.windowHeight;

        const offset = Math.min(ww, wh) / 25;
        // const vww = ww - offset * 2; // vww means `Virtual Window Width`
        // const vwh = wh - offset * 2; // vww means `Virtual Window Height`

        const s = this.p5.second();
        const m = this.p5.minute();
        const h = this.p5.hour();

        const outerMargin = 15;
        const outerEdge = Math.min(ww, wh);
        const innerEdge = outerEdge - outerMargin * 2;
        const innerMargin = innerEdge / 75;

        // this.p5.stroke('red');
        // this.p5.rect(
        //   ww / 2 - outerEdge / 2,
        //   wh / 2 - outerEdge / 2,
        //   outerEdge,
        //   outerEdge,
        // );
        // this.p5.line(
        //   ww / 2 - innerEdge / 2 + innerEdge / 3,
        //   wh / 2 - outerEdge / 2,
        //   ww / 2 - innerEdge / 2 + innerEdge / 3,
        //   wh / 2 - outerEdge / 2 + outerEdge,
        // );
        // this.p5.line(
        //   ww / 2 - innerEdge / 2 + (innerEdge * 2) / 3,
        //   wh / 2 - outerEdge / 2,
        //   ww / 2 - innerEdge / 2 + (innerEdge * 2) / 3,
        //   wh / 2 - outerEdge / 2 + outerEdge,
        // );
        // this.p5.rect(
        //   ww / 2 - outerEdge / 2,
        //   wh / 2 - outerEdge / 2,
        //   outerMargin,
        //   outerMargin,
        // );
        // this.p5.rect(
        //   ww / 2 - outerEdge / 2 + outerMargin,
        //   wh / 2 - outerEdge / 2 + outerMargin,
        //   outerEdge - outerMargin * 2,
        //   outerEdge - outerMargin * 2,
        // );

        // this.p5.stroke('#000000').rect(0, 0, offset, offset)
        //   .rect(ww - offset, 0, offset, offset)
        //   .rect(0, wh - offset, offset, offset)
        //   .rect(ww - offset, wh - offset, offset, offset);
        // this.p5.noFill().rect(offset, offset, vww, vwh);

        // this.p5.line(
        //   vww / 3 + offset, offset,
        //   vww / 3 + offset, offset + vwh,
        // ).line(
        //   (vww * 2) / 3 + offset, offset,
        //   (vww * 2) / 3 + offset, offset + vwh,
        // );

        const tl = offset / 3; // corner rounded
        const splitted = 6;
        for (let i = 0; i < splitted; i += 1) {
          if (i === 5) {
            if (s % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 4) {
            if (Math.trunc((s / 2)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 3) {
            if (Math.trunc((s / 4)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 2) {
            if (Math.trunc((s / 8)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 1) {
            if (Math.trunc((s / 16)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 0) {
            if (Math.trunc((s / 32)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          }
          // this.p5.stroke(this.colors[this.colorScheme]).rect(
          //   (vww * 2) / 3 + offset + offset * 0.25, // x
          //   (vwh * i) / splitted + offset + offset * 0.125, // y
          //   vww / 3 - offset * 0.5, // width
          //   vwh / splitted - offset * 0.25, // height
          //   tl, // corner
          // );

          this.p5.stroke(this.colors[this.colorScheme]).rect(
            ww / 2 - innerEdge / 2 + (innerEdge * 2) / 3 + innerMargin / 2, // x
            (wh / 2 - innerEdge / 2) + (innerEdge / 6) * i + innerMargin / 2, // y
            innerEdge / 3 - innerMargin, // width
            innerEdge / 6 - innerMargin, // height
            tl, // corner
          );

          if (i === 5) {
            if (m % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 4) {
            if (Math.trunc((m / 2)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 3) {
            if (Math.trunc((m / 4)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 2) {
            if (Math.trunc((m / 8)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 1) {
            if (Math.trunc((m / 16)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 0) {
            if (Math.trunc((m / 32)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          }

          // this.p5.stroke(this.colors[this.colorScheme]).rect(
          //   vww / 3 + offset + offset * 0.25, // x
          //   (vwh * i) / splitted + offset + offset * 0.125, // y
          //   vww / 3 - offset * 0.5, // width
          //   vwh / splitted - offset * 0.25, // height
          //   tl, // corner
          // );

          this.p5.stroke(this.colors[this.colorScheme]).rect(
            ww / 2 - innerEdge / 2 + innerEdge / 3 + innerMargin / 2, // x
            (wh / 2 - innerEdge / 2) + (innerEdge / 6) * i + innerMargin / 2, // y
            innerEdge / 3 - innerMargin, // width
            innerEdge / 6 - innerMargin, // height
            tl, // corner
          );
        }

        this.p5.noFill();

        for (let i = 0; i < 5; i += 1) {
          if (i === 4) {
            if (h % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 3) {
            if (Math.trunc((h / 2)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 2) {
            if (Math.trunc((h / 4)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 1) {
            if (Math.trunc((h / 8)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          } else if (i === 0) {
            if (Math.trunc((h / 16)) % 2 === 1) {
              this.p5.fill(this.colors[this.colorScheme]);
            } else {
              this.p5.noFill();
            }
          }

          this.p5.stroke(this.colors[this.colorScheme]).rect(
            ww / 2 - innerEdge / 2 + innerMargin / 2, // x
            (wh / 2 - innerEdge / 2) + (innerEdge / 5) * i + innerMargin / 2, // y
            innerEdge / 3 - innerMargin, // width
            innerEdge / 5 - innerMargin, // height
            tl, // corner
          );
        }
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
