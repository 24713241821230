<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';

const P5 = require('p5');

export default {
  name: 'DialBubble',
  setup() {
    return {
      colorScheme: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_greoih',
      p5: null,

      spring: 0.01, // 衝突時の反発
      gravity: 0.05, // 重力
      friction: -1, // 反発力
      bubbles: [],

      nowDay: null,
      nowHour: null,
      nowMinute: null,
      nowSecond: null,
    };
  },
  methods: {
    addBubble(type = 'second') {
      this.bubbles.push({
        type,
        createdDay: this.nowDay,
        createdHour: this.nowHour,
        createdMinute: this.nowMinute,
        createdSecond: this.nowSecond,
        x: Math.random() * window.innerWidth,
        y: Math.random() * (window.innerHeight / 4),
        vx: (Math.random() - 0.5) * 5,
        vy: -Math.random() * 5,
        // eslint-disable-next-line
        r: type === 'second' ? 15 : (type === 'minute' ? 45 : 90),
        alpha: 200,
      });
    },
    p5script(_p5) {
      this.p5 = Object.assign(_p5);
      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);

        this.nowDay = this.p5.day();
        this.nowHour = this.p5.hour();
        this.nowMinute = this.p5.minute();
        this.nowSecond = this.p5.second();

        this.p5.noStroke();

        for (let i = 0; i < this.nowSecond; i += 1) this.addBubble('second');
        for (let i = 0; i < this.nowMinute; i += 1) this.addBubble('minute');
        for (let i = 0; i < this.nowHour; i += 1) this.addBubble('hour');
      };

      this.p5.draw = () => {
        this.p5.clear();

        const width = window.innerWidth;
        const height = window.innerHeight;

        if (this.nowSecond !== this.p5.second()) {
          this.addBubble('second');
          this.nowSecond = this.p5.second();
        }

        if (this.nowMinute !== this.p5.minute()) {
          this.addBubble('minute');
          this.nowMinute = this.p5.minute();
        }

        if (this.nowHour !== this.p5.hour()) {
          this.addBubble('hour');
          this.nowHour = this.p5.hour();
        }

        for (let i = 0; i < this.bubbles.length; i += 1) {
          const bubble = this.bubbles[i];

          // move function
          bubble.vy += this.gravity;
          bubble.x += bubble.vx;
          bubble.y += bubble.vy;
          if (bubble.x + bubble.r / 2 > width) {
            bubble.x = width - bubble.r / 2;
            bubble.vx *= this.friction;
          } else if (bubble.x - bubble.r / 2 < 0) {
            bubble.x = bubble.r / 2;
            bubble.vx *= this.friction;
          }
          if (bubble.y + bubble.r / 2 > height) {
            bubble.y = height - bubble.r / 2;
            bubble.vy *= this.friction;
          } else if (bubble.y - bubble.r / 2 < 0) {
            bubble.y = bubble.r / 2;
            bubble.vy *= this.friction;
          }

          // collide function
          for (let j = i + 1; j < this.bubbles.length; j += 1) {
            const dx = this.bubbles[j].x - bubble.x;
            const dy = this.bubbles[j].y - bubble.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const minDist = this.bubbles[j].r / 2 + bubble.r / 2;
            if (distance < minDist) {
              const angle = Math.atan2(dy, dx);
              const targetX = bubble.x + Math.cos(angle) * minDist;
              const targetY = bubble.y + Math.sin(angle) * minDist;
              const ax = (targetX - this.bubbles[j].x) * this.spring;
              const ay = (targetY - this.bubbles[j].y) * this.spring;
              bubble.vx -= ax;
              bubble.vy -= ay;
              this.bubbles[j].vx += ax;
              this.bubbles[j].vy += ay;
            }
          }

          if (bubble.type === 'second' && bubble.createdMinute !== this.nowMinute) {
            bubble.r += 1;
            bubble.alpha -= 5;
          }
          if (bubble.type === 'minute' && bubble.createdHour !== this.nowHour) {
            bubble.r += 2;
            bubble.alpha -= 5;
          }
          if (bubble.type === 'hour' && bubble.createdDay !== this.nowDay) {
            bubble.r += 3;
            bubble.alpha -= 5;
          }

          // display function
          if (bubble.type === 'second') {
            this.p5.fill(255, 0, 0, bubble.alpha);
          } else if (bubble.type === 'minute') {
            this.p5.fill(0, 255, 0, bubble.alpha);
          } else {
            this.p5.fill(0, 0, 255, bubble.alpha);
          }
          this.p5.circle(bubble.x, bubble.y, bubble.r);
        }

        // 透明度が0より小さいバブルを削除
        this.bubbles = this.bubbles.filter((bubble) => bubble.alpha > 0);
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
