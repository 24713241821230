import { createApp } from 'vue';

import './assets/sass/main.scss'; // Style

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import $const from './plugins/const';

createApp(App)
  .use(store)
  .use(router)
  .use($const)
  .mount('#app');
