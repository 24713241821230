<template>
  <div :id="id"></div>
</template>

<script>
import { inject } from 'vue';
import P5 from 'p5';

export default {
  name: 'DialSimple',
  setup() {
    return {
      cs: inject('colorScheme'),
    };
  },
  data() {
    return {
      id: '_WAdR3e',
      p5: null,
      padding: 25, // => padding 25px
      colors: {
        light: {
          base: '#6e4a55',
        },
        dark: {
          base: '#e4d2d8',
        },
      },
    };
  },
  methods: {
    p5script(_p5) {
      this.p5 = Object.assign(_p5);
      this.p5.setup = () => {
        this.p5.windowWidth = window.innerWidth;
        this.p5.windowHeight = window.innerHeight;

        const canvas = this.p5.createCanvas(window.innerWidth, window.innerHeight);
        canvas.id(`${this.id}-canvas`);
      };

      this.p5.draw = () => {
        this.p5.clear();

        const cx = this.p5.windowWidth / 2;
        const cy = this.p5.windowHeight / 2;
        const diameter = Math.min(this.p5.windowWidth, this.p5.windowHeight) - this.padding * 2;

        const s = this.p5.map(
          this.p5.second() + new Date().getMilliseconds() / 1000,
          0, 60, 0, this.p5.TWO_PI,
        ) - this.p5.HALF_PI;
        const m = this.p5.map(
          this.p5.minute() + this.p5.norm(this.p5.second(), 0, 60),
          0, 60, 0, this.p5.TWO_PI,
        ) - this.p5.HALF_PI;
        const h = this.p5.map(
          this.p5.hour() + this.p5.norm(this.p5.minute(), 0, 60),
          0, 24, 0, this.p5.TWO_PI * 2,
        ) - this.p5.HALF_PI;

        this.p5.strokeWeight(1)
          .stroke(this.colors[this.cs].base)
          .fill(0, 0)
          .circle(cx, cy, diameter);

        this.p5.strokeCap(this.p5.ROUND)
          .stroke(this.colors[this.cs].base)
          .strokeWeight(1);
        const sr = (diameter / 2) * 0.71;
        this.p5.line(cx, cy, cx + this.p5.cos(s) * sr, cy + this.p5.sin(s) * sr);

        this.p5.strokeWeight(1.5);
        const mr = (diameter / 2) * 0.6;
        this.p5.line(cx, cy, cx + this.p5.cos(m) * mr, cy + this.p5.sin(m) * mr);

        this.p5.strokeWeight(3);
        const hr = (diameter / 2) * 0.5;
        this.p5.line(cx, cy, cx + this.p5.cos(h) * hr, cy + this.p5.sin(h) * hr);

        for (let a = 0; a < 360; a += 6) {
          if (a % 30 === 0) {
            const angle = this.p5.radians(a - 60);
            const x = cx + this.p5.cos(angle) * sr;
            const y = cy + this.p5.sin(angle) * sr;
            this.p5.noStroke().fill(this.colors[this.cs].base).circle(x, y, diameter / 250);
          } else {
            const angle = this.p5.radians(a - 60);
            const x = cx + this.p5.cos(angle) * sr;
            const y = cy + this.p5.sin(angle) * sr;
            this.p5.noStroke().fill(this.colors[this.cs].base).circle(x, y, diameter / 350);
          }
        }
      };

      this.p5.windowResized = () => {
        this.p5.resizeCanvas(this.p5.windowWidth, this.p5.windowHeight);
      };
    },
  },
  mounted() {
    // eslint-disable-next-line
    new P5(this.p5script, this.id);
  },
  unmounted() {
    this.p5.remove();
  },
};
</script>
