<template>
  <header><Navbar/></header>
  <div>
    <router-view/>
  </div>
</template>

<script>
import { provide, ref, readonly } from 'vue';
import Navbar from '@/components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  setup() {
    const colorScheme = ref('light');
    provide('colorScheme', readonly(colorScheme));
    const inversedColorScheme = ref('dark');
    provide('inversedColorScheme', readonly(inversedColorScheme));
    return {
      colorScheme,
      inversedColorScheme,
    };
  },
  methods: {
    isAvailableStorage(type) {
      let storage;
      try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22
          // Firefox
          || e.code === 1014
          // test name field too, because code might not be present
          // everything except Firefox
          || e.name === 'QuotaExceededError'
          // Firefox
          || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
          // acknowledge QuotaExceededError only if there's something already stored
          && (storage && storage.length !== 0);
      }
    },
    isAvailableShareAPI() { // Web Share APIが利用可能か判別
      if ('share' in navigator) this.$store.commit('activateShareAPI');
    },
    setColorScheme() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.colorScheme = 'dark';
        this.inversedColorScheme = 'light';
      } else {
        this.colorScheme = 'light';
        this.inversedColorScheme = 'dark';
      }
    },
    setFillHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  computed: {
    colorPalette() {
      return this.$store.getters.activeColorPalette;
    },
  },
  watch: {
    colorPalette(palette) {
      const rootStyle = document.documentElement.style;
      rootStyle.setProperty('--ds-color', `#${palette.light.bodycolor}`);
      rootStyle.setProperty('--ds-bg-color', `#${palette.light.background}`);
      rootStyle.setProperty('--ds-primary', `#${palette.light.primary}`);
      rootStyle.setProperty('--ds-light', `#${palette.light.light}`);
      rootStyle.setProperty('--ds-muted', `#${palette.light.muted}`);
    },
  },
  created() {
    // console.log(process.env);
    // ローカルストレージが利用可能かどうかを判定
    if (this.isAvailableStorage('localStorage')) {
      const storaged = localStorage.getItem('state');
      if (storaged) {
        this.$store.commit('changeSelectTarget', JSON.parse(storaged).activeSelectTarget);
        this.$store.commit('changeDialStyle', JSON.parse(storaged).activeDialName);
        this.$store.commit('changeColorPalette', JSON.parse(storaged).activeColorPalette);
      }

      this.$store.subscribe((mutation, state) => {
        if (
          mutation.type === 'changeSelectTarget'
          || mutation.type === 'changeDialStyle'
          || mutation.type === 'changeColorPalette'
        ) {
          this.$store.commit('save', state);
        }
      });
    }

    this.isAvailableShareAPI();
    this.setColorScheme();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      this.setColorScheme();
    });

    window.addEventListener('resize', this.setFillHeight);
    this.setFillHeight();
  },
};
</script>
