<template>
  <div
    class="w-100 h-100 position-absolute"
    style="top: 0; left: 0;"
  >
    <component :is="$store.getters.activeDialName"/>
  </div>
</template>

<script>
import Simple from '@/components/Dials/Simple.vue';
import Greece from '@/components/Dials/Greece.vue';
import Digital from '@/components/Dials/Digital.vue';
import Rainy from '@/components/Dials/Rainy.vue';
import TimeRing from '@/components/Dials/TimeRing.vue';
import Binary from '@/components/Dials/Binary.vue';
import Concentration from '@/components/Dials/Concentration.vue';
import Bubble from '@/components/Dials/Bubble.vue';

export default {
  components: {
    Simple,
    Greece,
    Digital,
    Rainy,
    TimeRing,
    Binary,
    Concentration,
    Bubble,
  },
  mounted() {
    document.activeElement.blur();
  },
};
</script>
