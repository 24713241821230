export default {
  install: (_app) => {
    const app = _app;
    const CONST = {
      app: {
        name: 'dials.style',
        designer: {
          name: 'MaRRoN',
          avatar: 'https://marron-web.site/images/profile.png',
          websiteurl: 'https://marron-web.site',
        },
      },
    };
    app.config.globalProperties.$const = CONST;
    app.provide('$const', CONST);
  },
};
